import { assertUnreachable, isPresent } from '@luminovo/commons';
import { PartLite, PartLiteTypes } from '@luminovo/http-client';

export function resolveOtsPartsRecursively(
    part: PartLite | undefined | null,
): Array<PartLite & { kind: PartLiteTypes.OffTheShelf }> {
    if (!isPresent(part)) {
        return [];
    }
    if (part.kind === PartLiteTypes.OffTheShelf) {
        return [part];
    }
    if (part.kind === PartLiteTypes.Generic) {
        return part.matches.flatMap((m) => resolveOtsPartsRecursively(m));
    }
    if (part.kind === PartLiteTypes.Ipn) {
        return part.matches.flatMap((m) => resolveOtsPartsRecursively(m));
    }
    if (part.kind === PartLiteTypes.RawSpecification) {
        return [];
    }
    if (part.kind === PartLiteTypes.Custom) {
        return [];
    }
    if (part.kind === PartLiteTypes.CustomComponent) {
        return [];
    }
    if (part.kind === PartLiteTypes.Unknown) {
        return [];
    }
    assertUnreachable(part);
}
