import { isPresent } from '@luminovo/commons';
import { PartLite, PartLiteTypes, QuoteRequestLineItemDTO, StandardPartTypes } from '@luminovo/http-client';

export function convertPartLiteToStandardPartDTO(
    part: PartLite | null | undefined,
    ipn?: string,
): { data: string; type: StandardPartTypes } | undefined {
    if (!isPresent(part)) {
        return undefined;
    }
    switch (part.kind) {
        case PartLiteTypes.OffTheShelf:
            return { data: part.id, type: StandardPartTypes.OffTheShelf };
        case PartLiteTypes.Generic:
            return { data: part.id, type: StandardPartTypes.Generic };
        case PartLiteTypes.Ipn:
            return { data: part.id, type: StandardPartTypes.Ipn };
        case PartLiteTypes.RawSpecification:
        case PartLiteTypes.Unknown:
            if (!ipn) {
                return undefined;
            }
            return { data: ipn, type: StandardPartTypes.Ipn };
        case PartLiteTypes.Custom:
        case PartLiteTypes.CustomComponent:
            // Not supported on the OTS supplier portal
            return undefined;
    }
}

/**
 * A quote request is a custom part quote request if all of its line items are custom parts.
 */
export function isCustomPartQuoteRequest(quoteRequestLineItems: QuoteRequestLineItemDTO[]) {
    return quoteRequestLineItems.every((item) => item.requested_part?.kind === PartLiteTypes.Custom);
}

export function isImportMatch(quoteRequestLineItemId: number, lumiQuoteId?: string) {
    if (!lumiQuoteId) {
        return false;
    }
    const ids = lumiQuoteId.split('|').map((x) => x.trim());
    return ids.some((idStr) => idStr === String(quoteRequestLineItemId));
}

/**
 * Scroll to the first element with the given selector.
 * If a ref is provided, scroll to the closest ancestor with the given selector.
 */
export function scrollToEditableCell(selector: 'error' | 'warning', ref?: HTMLElement | null) {
    // Find the first cell with error/warning or closest ancestor if ref provided
    const element = ref
        ? ref.closest(`tr`)?.querySelector(`[data-status="${selector}"]`)
        : document.querySelector(`[data-status="${selector}"]`);

    if (!element) {
        return;
    }

    // Scroll element into view with smooth animation
    element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
    });
}
