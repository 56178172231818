import { Trans } from '@lingui/macro';
import { Flexbox, MaxWidthLayout, Text } from '@luminovo/design-system';
import { PageLayout } from '../../../../../../../../components/PageLayout';
import { DuplicateCustomPartOfferForm } from '../../../../../../../../modules/CustomPartOfferForm';
import { UrlParams } from '../../../../../../../../utils/routes';

const DuplicateCustomPartOfferPage = (
    urlParams: UrlParams<'/rfqs/:rfqId/sourcing/offer/custom-part/:offerId/duplicate'>,
): JSX.Element => {
    const { rfqId, offerId } = urlParams.pathParams;

    return (
        <PageLayout layout="fragment">
            <MaxWidthLayout innerMaxWidth={'65rem'}>
                <Flexbox alignItems={'center'} gap={8}>
                    <Text variant="h1">
                        <Trans>Duplicate custom part offer</Trans>
                    </Text>
                </Flexbox>

                <DuplicateCustomPartOfferForm rfqId={rfqId} offerId={offerId} />
            </MaxWidthLayout>
        </PageLayout>
    );
};

export default DuplicateCustomPartOfferPage;
