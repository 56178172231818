import { t, Trans } from '@lingui/macro';
import { isPresent, throwErrorUnlessProduction } from '@luminovo/commons';
import {
    colorSystem,
    Flexbox,
    MenuButton,
    MenuItem,
    PrimaryButton,
    SecondaryButton,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { OfferOriginEnum, RfqContext, StandardPartMarketOfferDTO, StandardPartOfferDTO } from '@luminovo/http-client';
import {
    formatSupplierAndStockLocationDTO,
    isInternalPartNumberOffer,
    isOffTheShelfPartOffer,
    isStandardPartMarketOffer,
    isStandardPartOffer,
    OfferChips,
    Solution,
} from '@luminovo/sourcing-core';
import { ContentCopyRounded, DeleteOutlineRounded, EditRounded, Launch, MoreHoriz, Sync } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { CloseDrawerButton, useDrawerContext } from '../../../../../components/contexts/ModalContext';
import ConfirmationDialogBox from '../../../../../components/dialogBox/ConfirmationDialogBox';
import { httpQueryKey } from '../../../../../resources/http/httpQueryKey';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import { analytics } from '../../../../../utils/analytics';
import { route } from '../../../../../utils/routes';
import { usePollHttpQuery } from '../../../../../utils/usePollHttpQuery';
import { OfferOriginTag } from './OfferOriginTag';
import { StandardPartInformation } from './PartInformation';

const getEditAndDuplicateOTSOfferLink = (
    rfqContext: RfqContext,
    offer: StandardPartOfferDTO,
): { editLink: string; duplicateLink: string } => {
    if (rfqContext.type === 'WithinRfQ') {
        return {
            editLink: route('/rfqs/:rfqId/sourcing/offer/off-the-shelf/:offerId/edit', {
                rfqId: rfqContext.rfq_id,
                offerId: offer.id,
            }),
            duplicateLink: route('/rfqs/:rfqId/sourcing/offer/off-the-shelf/:offerId/duplicate', {
                rfqId: rfqContext.rfq_id,
                offerId: offer.id,
            }),
        };
    }
    return {
        editLink: route('/parts/offers/:offerId/edit', {
            offerId: offer.id,
        }),
        duplicateLink: route('/parts/offers/:offerId/duplicate', {
            offerId: offer.id,
        }),
    };
};

const ManualOfferActionsButton: React.FunctionComponent<{ offer: StandardPartOfferDTO; rfqContext: RfqContext }> = ({
    offer,
    rfqContext,
}): JSX.Element => {
    const { closeDrawer } = useDrawerContext();

    const { mutateAsync } = useHttpMutation('DELETE /offers/off-the-shelf/:id', {
        snackbarMessage: t`Offer successfully deleted`,
    });
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const isManualOffer = offer.origin.origin === OfferOriginEnum.Manual;
    if (offer.origin.origin === OfferOriginEnum.Import || offer.origin.origin === OfferOriginEnum.Consigned) {
        return <></>;
    }

    const { editLink, duplicateLink } = getEditAndDuplicateOTSOfferLink(rfqContext, offer);

    return (
        <>
            <MenuButton
                label={<MoreHoriz fontSize="inherit" />}
                appearance="secondary"
                size="medium"
                style={{ minWidth: 16, padding: 8 }}
            >
                {isManualOffer && (isOffTheShelfPartOffer(offer) || isInternalPartNumberOffer(offer)) && (
                    <MenuItem startIcon={<EditRounded />} label={t`Edit`} href={editLink} />
                )}

                {(isOffTheShelfPartOffer(offer) || isInternalPartNumberOffer(offer)) && (
                    <MenuItem startIcon={<ContentCopyRounded />} label={t`Duplicate`} href={duplicateLink} />
                )}
                {isManualOffer && (
                    <MenuItem
                        startIcon={<DeleteOutlineRounded />}
                        label={t`Delete`}
                        variant={'destructive'}
                        onClick={() => setIsDialogOpen(true)}
                    />
                )}
            </MenuButton>
            <ConfirmationDialogBox
                title={t`Delete offer`}
                text={t`Are you sure you want to delete this offer?`}
                isDialogOpen={isDialogOpen}
                onConfirm={async () => {
                    await mutateAsync({ pathParams: { id: offer.id } });
                    setIsDialogOpen(false);
                    closeDrawer();
                }}
                onReject={() => setIsDialogOpen(false)}
            />
        </>
    );
};

const GoToOfferButton: React.FunctionComponent<{
    offer: StandardPartMarketOfferDTO;
}> = ({ offer }): JSX.Element => {
    const url = offer.offer_url;
    if (!isPresent(url)) {
        return <></>;
    }

    const supplier =
        isStandardPartOffer(offer) && isStandardPartMarketOffer(offer)
            ? formatSupplierAndStockLocationDTO(offer.linked_location)
            : 'InventorySite';

    return (
        <Tooltip title={t`Open supplier page`} placement="top" arrow>
            <PrimaryButton
                size="small"
                startIcon={<Launch />}
                onClick={() => {
                    analytics.track('click_on_supplier_offer_link', { triggered_on_screen: 'OfferDrawer', supplier });
                    window.open(url, '_blank', 'noopener noreferrer');
                }}
            >
                <Trans>Go to offer</Trans>
            </PrimaryButton>
        </Tooltip>
    );
};

export const MarketOfferHeader: React.FunctionComponent<{
    offer: StandardPartMarketOfferDTO;
    rfqContext: RfqContext;
    solution?: Solution;
    fallbackSolutionTags?: Solution['solutionTags'];
}> = ({ offer, rfqContext, solution, fallbackSolutionTags }): JSX.Element => {
    return (
        <Flexbox
            flexDirection={'column'}
            gap={20}
            padding={'16px'}
            bgcolor={colorSystem.neutral[0]}
            borderBottom={`1px solid ${colorSystem.neutral[2]}`}
        >
            <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                <CloseDrawerButton disableMargin={true} />
                <Flexbox alignItems={'center'} gap={8}>
                    <OfferOriginTag offer={offer} origin={offer.origin} />
                    <UpdateOfferButton offer={offer} />
                    <GoToOfferButton offer={offer} />
                    <ManualOfferActionsButton offer={offer} rfqContext={rfqContext} />
                </Flexbox>
            </Flexbox>
            <Flexbox flexDirection={'column'} gap={12}>
                <Text variant="h2" color={colorSystem.neutral[8]}>
                    {formatSupplierAndStockLocationDTO(offer.linked_location)}
                </Text>
                <OfferChips solution={solution} fallbackSolutionTags={fallbackSolutionTags} />
            </Flexbox>
            <StandardPartInformation offer={offer} solution={solution} rfqContext={rfqContext} />
        </Flexbox>
    );
};

function UpdateOfferButton({ offer }: { offer: StandardPartMarketOfferDTO }) {
    const [taskId, setTaskId] = useState<string>('');
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const { isPolling: isPollingUpdates } = usePollHttpQuery(
        'GET /parts/library/search/progress/:taskId',
        {
            pathParams: { taskId },
            // eslint-disable-next-line camelcase
            queryParams: { get_part_offer_status: true },
        },
        {
            enabled: Boolean(taskId),
            isDone: (res) => {
                const count = res.parts.find((p) => p.id)?.count ?? 0;
                return count === 0;
            },
            onPollingDone() {
                setTaskId('');
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /solutions') });
                enqueueSnackbar(t`Offer update is complete`, {
                    variant: 'success',
                    autoHideDuration: 2000,
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                });
            },
        },
    );

    const { mutateAsync, isPending: isLoading } = useHttpMutation('POST /offer-update', {
        disableOnError: true,
        snackbarMessage: ({ responseBody }) => {
            const status = responseBody.status;
            if (typeof status === 'string' && status === 'InactiveAPI') {
                return { message: t`API is inactive. Please configure the API in the settings`, variant: 'error' };
            }
            if (status.RecentlyUpdated) {
                return { message: t`Offer is up to date`, variant: 'success' };
            }
            if (status.InProgress) {
                setTaskId(status.InProgress.progress_id);
                return { message: t`Updating offer...`, variant: 'info' };
            }
            if (status.RateLimited) {
                return { message: t`API is rate limited, please try again later`, variant: 'error' };
            }
            throwErrorUnlessProduction(new Error('Unknown status:' + JSON.stringify(status)));
            return { message: t`Unable to place offer update`, variant: 'error' };
        },
    });

    if (
        offer.origin.origin === OfferOriginEnum.Manual ||
        offer.origin.origin === OfferOriginEnum.Import ||
        offer.origin.origin === OfferOriginEnum.Consigned
    ) {
        return <></>;
    }

    return (
        <Tooltip title={t`Update this offer`}>
            <SecondaryButton
                startIcon={<Sync fontSize="inherit" />}
                onClick={async () => {
                    mutateAsync({
                        requestBody: {
                            api: offer.origin,
                            // eslint-disable-next-line camelcase
                            sourceable_part: offer.linked_part.id,
                        },
                    });
                }}
                isLoading={isLoading || isPollingUpdates}
                size="small"
            >
                {t`Update`}
            </SecondaryButton>
        </Tooltip>
    );
}
